import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
    selectedRoleId: null,
  },

  getters: {
    getOptionsById(state) {
      return state.data.map(item => ({ text: item.name, value: item.id }))
    },

    getOptionsByName(state) {
      return state.data.map(item => ({ text: item.name, value: item.name }))
    },

    // เอาไว้แสดงผลบนหัวตารางที่สองในหน้า Roles
    getRoleName(state) {
      if (state.selectedRoleId) {
        const filter = state.data.find(item => item.id === state.selectedRoleId)
        return `${filter.name} Role`
      }

      return null
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
    setSelectedRoleId(state, payload) {
      state.selectedRoleId = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const url = '/roles?perPage=10000&page=1&orderByColumn=name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 2)
      await context.commit('setData', data.data)
    }, // fetchOptions
  }, // actions
}
