export default [
  {
    path: '/wallets',
    name: 'wallets-list',
    component: () => import('@/views/heroai/wallets/WalletList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Wallets',
      breadcrumb: [
        {
          text: 'Wallets',
          active: true,
          to: '/wallets',
        },
      ],
      resource: 'Wallets',
      action: 'Access',
    },
  },
]
