import CampaignDetail from '@/models/Campaigns/Detail'

export default {
  namespaced: true,
  state: {
    campaign: new CampaignDetail(),
  },
  getters: {},
  mutations: {},
  actions: {},
}
