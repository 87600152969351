import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify, {
  theme: {
    /* primary: '#add14c', // green
    warning: '#fcaf67', // orange
    error: '#FF5252', // red
    accent: '#eab595', // brown
    info: '#abd1dc', // blue
    secondary: '#fd7ebf', // pink
    success: '#ccabd8', // violet */
  },
  options: {
    customProperties: true,
  },
  // iconfont: 'md',
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
})

export default Vuetify
