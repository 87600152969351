export default class ChatbotSectionInfo {
  constructor(item = {}) {
    return {
      existing_bot: item.existing_bot || '',
      chatbot_launch_date: item.chatbot_launch_date || '',
      flow_type: item.flow_type || '',
      auto_status_flag: item.auto_status_flag || '',
    }
  }
}
