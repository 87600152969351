export default class WebSolutionBriefing {
  constructor(item = {}) {
    return {
      did_phone: item.did_phone || [],
      domain_expire_date: item.domain_expire_date || '',
      client_website: item.client_website || '',
      email_lead_list: item.email_lead || [],
      email_notification_language: item.email_language || '5b63bbaf5e01bb6948695554',
      sms_lead_number: item.sms_lead || [],
      sms_notification_language: item.sms_language || '5b63bbaf5e01bb6948695554',
      line_notifications_language: item.line_notifications_language || '',
      landing_page_type: item.landing_page_type || '',
      landing_page_type_id: item.landing_page_type_id || '',
      landing_page_language: item.landing_page_language || '',
      landing_page_language_id: item.landing_page_language_id || '5b63bbaf5e01bb6948695554',
      landing_target_url: item.target_url || '',
      line_notification_number: item.line_notifications || [{
        tokens: '',
        email: '',
      }],
      line_notifications: item.line_notifications || '',
      line_notifications_email: item.line_notifications ? item.line_notifications.map(element => element.email) : [],
      email_language_name: item.email_language_name || '',
      sms_language_name: item.sms_language_name || '',
      line_notifications_language_id: item.line_notifications_language_id || '5b63bbaf5e01bb6948695554',
    }
  }
}
