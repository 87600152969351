export default [
  {
    path: '/sandbox-1',
    name: 'sandbox-1',
    component: () => import('@/views/sandbox/Sandbox1.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Sandbox 1',
      breadcrumb: [
        {
          text: 'Sandbox 1',
          active: true,
          to: '/sandbox-1',
        },
      ],
    },
  },
]
