export default class CreativeBriefingInfo {
  constructor(item = {}) {
    return {
      artwork_per_month: item.brief_commit_artwork || '',
      any_promotion: item.brief_promotion_offer || '',
      product: item.brief_product_service_branch || '',
      pieces: item.brief_pieces_sets || '0',
      lead_item: item.brief_lead_data_requirement || '',
      any_faq: item.brief_faq || '',
      creative_briefing_link: item.creative_briefing_link || '',
      campaign_creative_brief: item.campaign_creative_brief || {
        created_at: '',
        opportunity_id: '',
        unique_id: '',
      },
    }
  }
}
