import filters from './filters'

export default {
  filters,
  methods: {
    getMap(mapName, noAutoLoad = false) {
      return this.$store.getters.getMap(mapName, noAutoLoad)
    },
    getArray(mapName, noAutoLoad = false) {
      return this.$store.getters.getArray(mapName, noAutoLoad)
    },
    getObject(value, mapName, property = 'id') {
      return this.$store.getters.getObject(value, mapName, property)
    },
    printProperty(objectId, property, mapName, defaultValue = null) {
      const object = this.getObject(objectId, mapName)
      if (!object || (!object[property] && object[property] !== 0)) return defaultValue || '...'
      return object[property]
    },
    printName(objectId, mapName, defaultValue = null) {
      return this.printProperty(objectId, 'name', mapName, defaultValue)
    },
    printDate(date, format = 'DD/MM/YYYY HH:mm') {
      if (!date) return ''

      const newDate = (typeof date === 'string') ? date.replace(' ', 'T') : date
      return this.$moment(newDate).format(format)
    },
    printDateDefaultFormat(date) {
      if (!date) return this.printString(date)
      return this.printDate(date, this.DATE_FORMAT)
    },
    printTime(time) {
      if (!time) return ''
      if (time.length < 9) return time.slice(0, 5)
      return this.printDate(time, 'HH:mm')
    },
    getColor(mapInfo) {
      return this.getMap('colors')[mapInfo]
    },
    convertToArray(str, delimiter = ',') {
      return (str && typeof str === 'string') ? str.split(delimiter) : str
    },
    convertToString(arr, delimiter = ',') {
      return (arr && typeof arr !== 'string') ? arr.join(delimiter) : arr
    },
    detach(element, array) {
      const index = array.indexOf(element)
      if (index !== -1) array.splice(index, 1)
    },
    printBoolean(value) {
      return value ? this.$gettext('yes') : this.$gettext('no')
    },
    getStartTime(date) {
      return this.$moment(date).startOf('day').format(this.DATE_TIME_FORMAT)
    },
    getEndTime(date) {
      return this.$moment(date).endOf('day').format(this.DATE_TIME_FORMAT)
    },
    printString(value, defaultValue = '-') {
      return value || defaultValue
    },
    printCurrency(value, currency = 'THB') {
      if (!this.isNumber(value)) return this.printString(value)
      return this.$options.filters.currency(value, currency)
    },
    printDecimal(value) {
      if (!this.isNumber(value)) return this.printString(value)
      return this.$options.filters.decimal(value)
    },
    printPercent(value) {
      if (!this.isNumber(value)) return this.printString(value)

      return `${this.$options.filters.decimal(value)}%`
    },
    isNumber(value) {
      return (value || value === 0)
    },
    getHeroAIUrl(url = '') {
      return `${process.env.VUE_APP_REDIRECT_URL}${url}`
    },
    backToHeroAI(url = '') {
      window.location.href = this.getHeroAIUrl(url)
    },
  },
}
