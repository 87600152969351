const locales = [
  { locale: 'th-TH', currency: 'THB' },
  { locale: 'id-ID', currency: 'IDR' },
]

export default {
  currency: (value, currency = 'THB') => {
    const result = locales.find(locale => locale.currency === currency)

    if (!result) return value

    const { locale } = result
    const formattedValue = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }).format(value)

    return formattedValue.includes('NaN') ? value : formattedValue
  },

  decimal: value => {
    const formattedValue = new Intl.NumberFormat('th-TH', {
      maximumFractionDigits: 2,
    }).format(value)

    return formattedValue.includes('NaN') ? value : formattedValue
  },

  number: (value, fixed = 2) => {
    if (!value) return value

    return Number(value.toFixed(fixed))
  },
}
