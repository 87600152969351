export default [
  {
    path: '/',
    name: 'heroai-login',
    component: () => import('@/views/heroai/authentications/HeroAiLogin.vue'),
    meta: {
      pageTitle: 'Login',
      layout: 'full',
    },
  },

  {
    path: '/logout',
    name: 'heroai-logout',
    component: () => import('@/views/heroai/authentications/HeroAiLogout.vue'),
    meta: {
      pageTitle: 'Logout',
      layout: 'full',
    },
  },

  {
    path: '/client-login',
    name: 'heroai-client-login',
    component: () => import('@/views/client-logins/Default.vue'),
    meta: {
      requireLogout: true,
      pageTitle: 'Client Login',
      layout: 'full',
    },
  },

  {
    path: '/client-forget-password',
    name: 'heroai-client-forget-password',
    component: () => import('@/views/client-logins/ForgetPassword.vue'),
    meta: {
      requireLogout: true,
      pageTitle: 'Client Forget Password',
      layout: 'full',
    },
  },

  {
    path: '/client-reset-password',
    name: 'heroai-client-reset-password',
    component: () => import('@/views/client-logins/ResetPassword.vue'),
    meta: {
      requireLogout: true,
      pageTitle: 'Client Reset Password',
      layout: 'full',
    },
  },
]
