export default [
  {
    path: '/prompt-library',
    name: 'prompt-library',
    component: () => import('@/views/prompts/PromptList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Prompt Library',
      breadcrumb: [
        {
          text: 'Prompt Library',
          active: true,
          to: '/prompt-library',
        },
      ],
      resource: 'Prompts',
      action: 'Access',
    },
  },
]
