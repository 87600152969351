import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
// eslint-disable-next-line import/no-cycle
import ErrorHandler from '@/libs/ErrorHandler'

let token = localStorage.getItem('sanctumToken')
let axiosConfig = AxiosConfig.getJsonWithToken(token)

const BASE_URI = '/channel-performance'

export default {
  list(data) {
    let url = BASE_URI
    if (typeof data !== 'undefined') {
      const esc = encodeURIComponent
      const query = Object.keys(data)
        .map(k => `${esc(k)}=${esc(data[k])}`)
        .join('&')
      url += `?${query}`
    }
    return this.getAPI(url)
  },

  listTest() {
    const queryParams = `
      sort_item%5B0%5D%5Bname%5D=channel_status_id
      &sort_item%5B0%5D%5Bdirection%5D=asc
      &sort_item%5B1%5D%5Bname%5D=campaign_name
      &sort_item%5B1%5D%5Bdirection%5D=asc
      &sort_item%5B2%5D%5Bname%5D=name
      &sort_item%5B2%5D%5Bdirection%5D=asc
      &page=0
      &perPage=25
    `
    const url = `${BASE_URI}/assigned-for-user?${queryParams}`

    return this.getAPI(url)
  },

  add(channel) {
    const uri = `${BASE_URI}/new`
    console.log('add', uri, channel)

    // return ApiService().post(uri, channel);
  },

  update(channel) {
    const uri = `${BASE_URI}/update/${channel.id}`
    console.log('update', uri, channel)

    // return ApiService().put(uri, channel);
  },

  delete(channel) {
    const uri = `${BASE_URI}/delete/${channel.id}`
    console.log('delete', uri, channel)

    // return ApiService().delete(uri);
  },

  /**
   * รับข้อมูลตัวกรองสำหรับ Filter Panel
   *
   */
  getFilter() {
    const url = `${BASE_URI}/filters`
    const response = this.getAPI(url)
    return response
  },

  /**
   * รับข้อมูล Channel Performance Data จาก Redshift ผ่าน HeroAI
   *
   */
  getTableData(filter) {
    const url = `${BASE_URI}/list`
    return this.postAPI(url, null, filter)
  },

  searchCampaign(name) {
    const url = '/campaign/search'
    return this.getAPI(url, { name })
  },

  fetchData(camapignId, data) {
    const url = `/campaign/${camapignId}/analytics-data/fetch`
    return this.getAPI(url, { ...data })
  },

  async getAPI(url, qparam) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'get',
          headers: axiosConfig.headers,
          params: qparam,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  },

  async postAPI(url, qparam, data) {
    try {
      this.setAuthHeader()
      const response = await axios(
        url, {
          method: 'post',
          headers: axiosConfig.headers,
          params: qparam,
          data,
        },
      )
      return response.data
    } catch (error) {
      return new ErrorHandler(error)
    }
  },

  async setAuthHeader() {
    token = localStorage.getItem('sanctumToken')
    axiosConfig = AxiosConfig.getJsonWithToken(token)
  },
}
