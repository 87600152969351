/* ------------------------------------------------------------------------------------------------------------------ */
/* --- This one used in page settings/permissions ------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    loadedTime: null,
    doUpdate: false,
    backendPermissions: null,
    currentPermissions: [],
    subjectOptions: [],
    abilityOptions: [],
  },

  getters: {
    getSubjectOptions(state) {
      return state.subjectOptions
    },
    getAbilityOptions(state) {
      return state.abilityOptions
    },
  },

  mutations: {
    setLoadedTime(state, payload) {
      state.loadedTime = payload
    },
    setDoUpdate(state, payload) {
      state.doUpdate = payload
    },
    setBackendPermissions(state, payload) {
      state.backendPermissions = payload
    },
    setCurrentPermissions(state, payload) {
      state.currentPermissions = payload.map(permission => ({ subject: permission.subject, ability: permission.ability }))
    },
    setSubjectOptions(state, payload) {
      state.subjectOptions = payload
    },
    setAbilityOptions(state, payload) {
      state.abilityOptions = payload
    },
  },

  actions: {
    async fetchSubjects(context) {
      // เราจะทำการเปรียบเทียบ Permission ที่ผู้ใช้สร้างเอาไว้แล้วกับรายการ Permission ที่มีอยู่ทั้งหมดจาก Backend
      // ถ้าหากตรงกัน เราจะทำการปิดไม่ให้แสดงในรายการของ Dropdown โดยเราจะทำการแปลงรูปแบบข้อมูลให้อยู่ในรูปแบบนี้
      // { subject: 'subject', ability: 'ability', display: true }

      // รายการ Permission ที่แสดงอยู่ในหน้า Permission List
      const { currentPermissions } = context.state

      // รายการ Permission ทั้งหมดที่ได้มาจาก Backend
      const url = '/permissions/subjectAndAbilities'
      const data = await DropdownService.loadData(url, 2)
      const backendPermissions = []

      const subjects = Object.keys(data)
      subjects.forEach(subject => {
        data[subject].forEach(item => {
          backendPermissions.push({ subject, ability: item, display: true })
        })
      })

      // ทำการเปรียบเทียบ
      currentPermissions.forEach(currentPermission => {
        backendPermissions.forEach(backendPermission => {
          if (currentPermission.subject === backendPermission.subject && currentPermission.ability === backendPermission.ability) {
            // eslint-disable-next-line no-param-reassign
            backendPermission.display = false
          }
        })
      })

      // สร้างรายการ Subject
      let filterSubjects = []
      backendPermissions.forEach(backendPermission => {
        if (backendPermission.display) {
          filterSubjects.push(backendPermission.subject)
        }
      })
      filterSubjects = new Set(filterSubjects) // ให้แปลง Array เป็น Set ซึ่งใน ES6 มันจะทำการลบข้อมูลที่ซ้ำกันออกให้เอง
      filterSubjects = [...filterSubjects] // จากนั้นให้แปลง Set กลับเป็น Array อีกครั้ง

      const subjectOptions = filterSubjects.map(subject => ({ text: subject, value: subject }))

      context.commit('setBackendPermissions', backendPermissions)
      context.commit('setSubjectOptions', subjectOptions)
    },

    fetchAbilities(context, subject) {
      const { backendPermissions } = context.state
      const permissions = []

      backendPermissions.forEach(permission => {
        if (permission.subject === subject && permission.display === true) {
          permissions.push(permission)
        }
      })

      const abilityOptions = permissions.map(permission => ({ text: permission.ability, value: permission.ability }))

      context.commit('setAbilityOptions', abilityOptions)
    },
  },
}
