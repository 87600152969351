import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'
import CampaignOverview from './CampaignOverview'
import ChatbotSectionInfo from './ChatbotSection'
import CreativeBriefingInfo from './CreativeBriefing'
import DocumentSection from './DocumentSection'
import MediaBrief from './MediaBriefing'
import OpportunityOverview from './OpportunityOverview'
import StaffAssignment from './StaffAssignment'
import SystemDetail from './SystemDetail'
import WebSolutionBriefing from './WebSolutionBriefing'

export default class CampaignDetail {
  constructor(item = {}) {
    return {
      campaign_overview: new CampaignOverview(item.campaign_overview),
      creative_briefing: new CreativeBriefingInfo(item.creative_briefing),
      document_section: new DocumentSection(item.document_section),
      seo_requirements_section: new SEORequirementsSection(item.seo_requirements_section),
      media_briefing: new MediaBrief(item.media_briefing),
      opportunity_overview: new OpportunityOverview(item.opportunity_overview),
      staff: new StaffAssignment(item.staff),
      system_details: new SystemDetail(item.system_details),
      web_solution_briefing: new WebSolutionBriefing(item.web_solution_briefing),
      chatbot: new ChatbotSectionInfo(item.chatbot_section),
    }
  }
}
