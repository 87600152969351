export default [
  {
    path: '/domains',
    name: 'domains',
    component: () => import('@/views/domains/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Domains',
      breadcrumb: [
        {
          text: 'Domains',
          active: true,
          to: '/domains',
        },
      ],
      resource: 'Domains',
      action: 'Access',
    },
  },
]
