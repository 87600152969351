export default [
  {
    path: '/masters/countries',
    name: 'masters-countries-list',
    component: () => import('@/views/heroai/masters/countries/CountryList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Countries',
      breadcrumb: [
        {
          text: 'Countries',
          active: true,
          to: '/masters/countries',
        },
      ],
      resource: 'Countries',
      action: 'Access',
    },
  },

  {
    path: '/masters/kpi-units',
    name: 'masters-kpi-units-list',
    component: () => import('@/views/heroai/masters/kpi-units/KpiUnitList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'KPI Units',
      breadcrumb: [
        {
          text: 'KPI Units',
          active: true,
          to: '/masters/kpi-units',
        },
      ],
      resource: 'KPI Units',
      action: 'Access',
    },
  },

  {
    path: '/masters/languages',
    name: 'masters-languages-list',
    component: () => import('@/views/heroai/masters/languages/LanguageList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Languages',
      breadcrumb: [
        {
          text: 'Languages',
          active: true,
          to: '/masters/languages',
        },
      ],
      resource: 'Languages',
      action: 'Access',
    },
  },

  {
    path: '/masters/lead-sources',
    name: 'masters-lead-sources-list',
    component: () => import('@/views/heroai/masters/lead-sources/LeadSourceList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Lead Sources',
      breadcrumb: [
        {
          text: 'Lead Sources',
          active: true,
          to: '/masters/lead-sources',
        },
      ],
      resource: 'Lead Sources',
      action: 'Access',
    },
  },

  {
    path: '/masters/metric-units',
    name: 'masters-metric-units-list',
    component: () => import('@/views/heroai/masters/metric-units/MetricUnitList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Metric Units',
      breadcrumb: [
        {
          text: 'Metric Units',
          active: true,
          to: '/masters/metric-units',
        },
      ],
      resource: 'Metric Units',
      action: 'Access',
    },
  },

  {
    path: '/masters/campas-point-limit',
    name: 'masters-campas-point-limit-list',
    component: () => import('@/views/heroai/masters/campas-point-limit/CampasPointLimitList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Campas Point Limits',
      breadcrumb: [
        {
          text: 'Campas Point Limits',
          active: true,
          to: '/masters/campas-point-limit',
        },
      ],
      resource: 'Campus Point Limits',
      action: 'Access',
    },
  },

  {
    path: '/masters/user-positions',
    name: 'masters-user-positions-list',
    component: () => import('@/views/heroai/masters/user-positions/UserPositionList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'User Positions',
      breadcrumb: [
        {
          text: 'User Positions',
          active: true,
          to: '/masters/positions',
        },
      ],
      resource: 'User Positions',
      action: 'Access',
    },
  },

  {
    path: '/masters/exchangerates',
    name: 'masters-exchange-rates-list',
    component: () => import('@/views/heroai/masters/exchange-rates/ExchangeRateList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Exchange Rates',
      breadcrumb: [
        {
          text: 'Exchange Rates',
          active: true,
          to: '/masters/exchangerates',
        },
      ],
      resource: 'Exchange Rates',
      action: 'Access',
    },
  },
]
