import store from '@/store'
import { Ability } from '@casl/ability'

// ค่า Ability ตรงนี้จะถูกสร้างเมื่อผู้ใช้ทำการโหลดหน้าจอขึ้นมาใหม่
// ซึ่งสามารถทำให้เราได้รับค่าว่างกลับมาเป็นค่าเริ่มต้นแทน ถ้าหากผู้ใช้งานยังไม่ได้ทำการเข้าระบบ
// เราจะทำการ convert ค่า Permission ให้เข้ากับรูปแบบของ Ability อีกครั้งจาก หลังจากทำการเข้าสู่ระบบแล้ว
// เราจะทำการอัพเดตค่า Ability อีกครั้งจาก @/layouts/layout-vertical/LayoutVertical.vue
// ซึ่ง Layout จะถูกโหลดหลังจากผู้ใช้เข้าระบบมาแล้ว ทำให้สามารถอัพเดต Ability เป็นค่าล่าสุดจาก Store ได้

export default new Ability(store.getters['heroAiAuthentications/getPermissions'])
