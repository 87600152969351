export default [
  {
    path: '/product-offering/products',
    name: 'products-list',
    component: () => import('@/views/heroai/product-offering/products/ProductList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          active: true,
          to: '/product-offering/products',
        },
      ],
      resource: 'Products',
      action: 'Access',
    },
  },

  {
    path: '/product-offering/product-families',
    name: 'product-families-list',
    component: () => import('@/views/heroai/product-offering/product-families/ProductFamilyList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Product Families',
      breadcrumb: [
        {
          text: 'Product Families',
          active: true,
          to: '/product-offering/product-families',
        },
      ],
      resource: 'Products Families',
      action: 'Access',
    },
  },
]
