import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
    productFamilyId: '',
  },

  getters: {
    getOptions(state) {
      return state.data.map(item => ({ text: item.name, value: item.id, product_family_id: item.product_family_id }))
    },
    getOptionsByProductFamilyId(state, getters) {
      const options = []

      if (state.productFamilyId) {
        getters.getOptions.forEach(item => {
          if (item.product_family_id === state.productFamilyId) {
            options.push(item)
          }
        })
      }

      return options
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
    setProductFamilyId(state, payload) {
      state.productFamilyId = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const url = '/dropdown-options/product-options?perPage=10000&page=1&orderByColumn=name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 2)
      await context.commit('setData', data.data)
    }, // fetchOptions
  }, // actions
}
