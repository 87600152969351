export default class SEORequirementsSection {
  constructor(item = {}) {
    return {
      seo_google_analytic_account: item.seo_google_analytic_account || '',
      seo_google_search_console_account: item.seo_google_search_console_account || '',
      seo_real_time_dashboard_access_email: item.seo_real_time_dashboard_access_email || [],
      seo_focus_page: item.seo_focus_page || '',
      seo_brand_corporate_identity: item.seo_brand_corporate_identity || '',
      seo_brand_keyword: item.seo_brand_keyword || '',
      seo_core_product_or_services: item.seo_core_product_or_services || '',
      seo_communication_marketing_plan: item.seo_communication_marketing_plan || '',
      seo_industry_leader: item.seo_industry_leader || '',
      seo_main_competitors: item.seo_main_competitors || [],
      seo_note: item.seo_note || '',
      seo_briefing_url: item.seo_briefing_url || '',
      seo_client_report_url: item.seo_client_report_url || '',
    }
  }
}
