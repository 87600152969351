import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
  },

  getters: {
    getOptions(state) {
      return state.data.map(item => ({ text: item.name, value: item.id }))
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const url = '/userPositions?perPage=10000&page=1&orderByColumn=name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 2)
      await context.commit('setData', data.data)
    }, // fetchOptions
  }, // actions
}
