import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'

export default {
  async loadData(url, dataIndent) {
    const token = localStorage.getItem('sanctumToken')
    const axiosConfig = AxiosConfig.getJsonWithToken(token)

    const response = await axios.get(url, axiosConfig)
    let data = null

    switch (dataIndent) {
      case 1:
        data = response.data
        break
      case 2:
        data = response.data.data
        break
      case 3:
        data = response.data.data.data
        break
      case 4:
        data = response.data.data.data.data
        break
      default:
    }

    return data
  },
}
