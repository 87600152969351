import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    data: [],
  },

  getters: {
    getOptions(state) {
      return state.data.map(item => ({ text: item.name, value: `${item.id}` }))
    },
    getIsLoading(state) {
      return state.isLoading
    },
  },

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setData(state, payload) {
      state.data = payload
    },
  },

  actions: {
    async fetchOptions(context, { campaignId, channelId }) {
      context.commit('setIsLoading', true)

      if (campaignId || (campaignId && channelId)) {
        const url = `/dropdown-options/analytic-campaign-options?campaignId=${campaignId}&channelId=${channelId || ''}`
        const data = await DropdownService.loadData(url, 2)
        context.commit('setData', data.data)
      } else {
        context.commit('setData', [])
      }

      context.commit('setIsLoading', false)
    }, // fetchOptions
  }, // actions
}
