export default {
  namespaced: true,
  state: {
    options: [
      { text: 'Male', value: 'Male' },
      { text: 'Female', value: 'Female' },
      { text: 'Others', value: 'Others' },
    ],
  },

  getters: {
    getOptions(state) {
      return state.options
    },
  },

  mutations: {},

  actions: {},
}
