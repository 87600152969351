export default {
  namespaced: true,
  state: {
    campaign_id: '',
    campaign: {},
    creative_briefing_info: {},
    web_sol_briefing: {},
    media_briefing: {},
    chatbot_section_info: {},
    document_section: {},
    seoRequirementsSection: {},
    staff_assignment: {},
    campaign_products: {},
    fillMedia: {},
    selectedProducts: [],
    product_table: {},
    opportunity_id: '',
    remainingAmount: 0,
    cycleAmount: 1,
    addProduct: {},
    opportunity_overview: {},
    accountState: '',
    opportunityState: '',
    campaignCycle: [],
    isDeleteChange: false,
    isAddProduct: false,
    mediaCampaignRemaining: 0,
    cycleTotals: [],
    cycleTotalsHistory: [],
    cycleTotalsRemaining: [],
    management_fee: '',
    retain_budget: 0,
    historySelected: {},
    historyState: 0,
    opportunityHistory: [],
    paymentCalendarState: false,
    isPendingApproval: false,
    cycleDisableDates: [],
    totalBudget: 0,
  },

  getters: {
    getCampaignId(state) {
      return state.campaign_id
    },
    getCampaign(state) {
      return state.campaign
    },
    getCreativeBriefingInfo(state) {
      return state.creative_briefing_info
    },
    getWebSolutionBriefing(state) {
      return state.web_sol_briefing
    },
    getMediaBriefing(state) {
      return state.media_briefing
    },
    getChatbotSectionInfo(state) {
      return state.chatbot_section_info
    },
    getDocumentSection(state) {
      return state.document_section
    },
    getSEORequirementsSection(state) {
      return state.seoRequirementsSection
    },
    getStaffAssignment(state) {
      return state.staff_assignment
    },
    getCampaignProducts(state) {
      return state.campaign_products
    },
    getFillMedia(state) {
      return state.fillMedia
    },
    getSelectedProducts(state) {
      return state.selectedProducts
    },
    getProductTable(state) {
      return state.product_table
    },
    getOpportunityId(state) {
      return state.opportunity_id
    },
    getRemainingAmount(state) {
      return state.remainingAmount
    },
    getCycleAmount(state) {
      return state.cycleAmount
    },
    getAddProduct(state) {
      return state.dataModal
    },
    getOpportunityOverview(state) {
      return state.opportunity_overview
    },
    getAccountState(state) {
      return state.accountState
    },
    getOpportunityState(state) {
      return state.opportunityState
    },
    getCampaignCycle(state) {
      return state.campaignCycle
    },
    getIsDelete(state) {
      return state.isDelete
    },
    getMediaCampaignRemaining(state) {
      return state.mediaCampaignRemaining
    },
    getIndex(state) {
      return state.index
    },
    getManagementFee(state) {
      return state.index
    },
    getRetainBudget(state) {
      return state.retain_budget
    },
    getCycleDisableDates(state) {
      return state.cycleDisableDates
    },
    getTotalBudget(state) {
      return state.totalBudget
    },
  },

  mutations: {
    setCampaign(state, payload) {
      state.campaign = payload
    },
    setCampaignId(state, payload) {
      state.campaign_id = payload
    },
    setCreativeBriefingInfo(state, payload) {
      state.creative_briefing_info = payload
    },
    setWebSolutionBriefing(state, payload) {
      state.web_sol_briefing = payload
    },
    setMediaBriefing(state, payload) {
      state.media_briefing = payload
    },
    setChatbotSectionInfo(state, payload) {
      state.chatbot_section_info = payload
    },
    setDocumentSection(state, payload) {
      state.document_section = payload
    },
    setSEORequirementsSection(state, payload) {
      state.seoRequirementsSection = payload
    },
    setStaffAssignment(state, payload) {
      state.staff_assignment = payload
    },
    setCampaignProducts(state, payload) {
      state.campaign_products = payload
    },
    setFillMedia(state, payload) {
      state.fillMedia = payload
    },
    setselectedProducts(state, payload) {
      state.selectedProducts = payload
    },
    setProductTable(state, payload) {
      state.product_table = payload
    },
    setOpportunityId(state, payload) {
      state.opportunity_id = payload
    },
    setRemainingAmount(state, payload) {
      state.remainingAmount = payload || 0
    },
    setCycleAmount(state, payload) {
      state.cycleAmount = payload || 1
    },
    setAddProduct(state, payload) {
      state.dataModal = payload
    },
    setOpportunityOverview(state, payload) {
      state.opportunity_overview = payload
    },
    setAccountState(state, payload) {
      state.accountState = payload
    },
    setOpportunityState(state, payload) {
      state.opportunityState = payload
    },
    setCampaignCycle(state, payload) {
      state.campaignCycle = payload
    },
    setIsDelete(state, payload) {
      state.isDelete = payload
    },
    setMediaCampaignRemaining(state, payload) {
      state.mediaCampaignRemaining = payload
    },
    setIndex(state, payload) {
      state.index = payload
    },
    setManagementFee(state, payload) {
      state.index = payload
    },
    setRetainBudget(state, payload) {
      state.retain_budget = payload
    },
    setCycleDisableDates(state, payload) {
      state.cycleDisableDates = payload
    },
    setTotalBudget(state, payload) {
      state.totalBudget = payload
    },
  },

  actions: {},
}
