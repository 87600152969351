export default {
  getJsonWithoutToken() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }

    return config
  },

  getJsonWithToken(token) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    return config
  },

  getLeadManagementConfig() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      withCredentials: false,
    }

    return config
  },
}
