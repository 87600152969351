export default [
  {
    path: '/campaigns',
    name: 'campaigns-list',
    component: () => import('@/views/heroai/campaigns/index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          active: true,
          to: '/campaigns',
        },
      ],
      resource: 'Campaigns',
      action: 'Access',
    },
  },
  {
    path: '/campaigns/create',
    name: 'campaign-create',
    component: () => import('@/views/heroai/campaigns/create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Add Campaign',
      breadcrumb: [
        {
          text: 'Campaigns',
          active: false,
          to: '/campaigns',
        },
        {
          text: 'Add Campaign',
          active: true,
          to: '/campaigns/create',
        },
      ],
      resource: 'Opportunities > Campaign',
      action: 'Create Campaign',
    },
  },
  {
    path: '/campaigns/:id',
    name: 'campaign-detail',
    component: () => import('@/views/heroai/campaigns/view.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Campaigns',
      breadcrumb: [
        {
          text: 'Campaigns',
          active: false,
          to: '/campaigns',
        },
        {
          text: 'Detail',
          active: true,
          to: '/campaigns/:id',
        },
      ],
      resource: 'Campaigns',
      action: 'Access',
    },
  },
  {
    path: '/retain-campaigns/:id',
    name: 'retain-campaign',
    component: () => import('@/views/heroai/campaigns/retain.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Retain Campaign',
      breadcrumb: [
        {
          text: 'Campaigns',
          active: false,
          to: '/campaigns',
        },
        {
          text: 'Detail',
          active: true,
          to: '/campaigns/:id',
        },
      ],
      resource: 'Campaigns > Campaign',
      action: 'Retain',
    },
  },
]
