import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
    options: [],
  },

  getters: {
    getOptions(state) {
      return state.options
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
    setOptions(state, payload) {
      state.options = payload
    },
  },

  actions: {
    async fetchOptions({ state, commit }) {
      const url = '/userLevels?perPage=10000&page=1&orderByColumn=level_name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 2)
      await commit('setData', data.data)

      const options = state.data.map(item => ({ text: `${item.level} - ${item.level_name}`, value: item.level }))

      commit('setOptions', options)
    }, // fetchOptions

    updateOptions({ state, commit }, userPositionId) {
      const { data } = state
      const filter = data.filter(item => item.user_position_id === userPositionId)
      const options = filter.map(item => ({ text: `${item.level} - ${item.level_name}`, value: item.level }))

      commit('setOptions', options)
    }, // updateOptions
  }, // actions
}
