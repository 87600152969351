export default class MediaBriefing {
  constructor(item = {}) {
    return {
      media_plan: item.media_plan || '',
      facebook_page_access: item.facebook_page_access || '',
      client_url: item.client_url || '',
      content_from_am: item.content_from_am || '',
      service_focus: item.service_focus || '',
      keyword_ideas: item.keyword_ideas || '',
      report_system: item.report_system || '',
      report_system_name: item.report_system_name || '',
      target_aud_location_focus: item.target_audience_location_focus || '',
      required_fields: item.required_fields || '',
      package_selling: item.package_selling || '',
      uniqued_selling_point: item.uniqued_selling_point || '',
      target_aud: item.target_audience || '',
      target_aud_level: item.target_audience_level_ids || [],
      additional_information: item.additional_information || '',
      // ad_language: item.ad_language || [
      //   {
      //     name: '',
      //     id: '',
      //   },
      // ],
      ad_language_names: item.ad_language_names || '',
      ad_language_ids: item.ad_language_ids || [],
    }
  }
}
