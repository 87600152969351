/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
export default class User {
  constructor(item = {}) {
    return {
      name: item.name || '',
      id: item.id || '',
      user_level_points: item.user_level_points || '',
      user_level_points_display: item.user_level_points_display || '0',
      ad_platform: item.ad_platform || '',
    }
  }
}
