export const array = map => {
  const arrayMap = []
  Object.keys(map).forEach(key => {
    arrayMap.push(map[key])
  })
  return arrayMap
}

export const autoLoad = async (map, mapName, module) => {
  // map have zero key
  // module is auto load
  // map is not marked as empty
  // when module is not loading
  // only do it 5 times
  const moduleAutoload = module.autoLoad
  if (Object.keys(map).length === 0
    && moduleAutoload
    && !moduleAutoload.emptyMap[mapName]
    && moduleAutoload.loading === false
    && (!moduleAutoload.counter || moduleAutoload.counter < 5)) {
    moduleAutoload.loading = true
    const mapNames = []
    Object.keys(module).forEach(key => {
      if (typeof (module[key]) === 'object' && Object.keys(module[key]).length === 0) {
        mapNames.push(key)
      }
    })
    const response = await moduleAutoload.loader.call(this, moduleAutoload.params)
    if (response) {
      const { data } = response
      mapNames.forEach(name => {
        if (data[name]) {
          data[name].forEach(item => {
            const key = item.id ? item.id : item.campaignId
            // eslint-disable-next-line no-param-reassign
            module[name][key] = item
          })
          if (Object.keys(module[name]).length === 0) {
            moduleAutoload.emptyMap[name] = true
          }
        } else {
          console.warn(`MODULE AUTOLOADED BUT API RESPONSE NO DATA FOR THIS MAP <${name}>`, module)
        }
      })
    }
    if (!moduleAutoload.counter) moduleAutoload.counter = 1
    else moduleAutoload.counter += 1
    moduleAutoload.loading = false
  }
}

export const isInThePast = date => {
  const checkDate = new Date(date)
  const today = new Date()
  return checkDate.getTime() < today.getTime()
}

export const isOfTheFuture = date => {
  const checkDate = new Date(date)
  const today = new Date()
  return checkDate.getTime() > today.getTime()
}

export const dateDiff = (from, to) => {
  const fromDate = new Date(from)
  const toDate = new Date(to)
  return parseInt((toDate - fromDate) / (1000 * 60 * 60 * 24), 10)
}

const percentUsed = (used, total) => {
  const userd = Number(used)
  const newtotal = Number(total)
  if (!userd || !newtotal) return 0
  const percent = (userd / newtotal) * 100
  return percent.toFixed(2, 10)
}

const division = (a, b) => {
  const first = Number(a)
  const second = Number(b)
  if (!first || !second) return 0
  return first / second
}

const formatDate = (dateString, separator = '/') => {
  const dateObj = new Date(dateString)
  const date = dateObj.getDate()
  const month = dateObj.getMonth()
  const year = dateObj.getFullYear()
  return `${date}${separator}${month + 1}${separator}${year}`
}

const generateCycleName = (cycle, filterBy = 'cycle') => {
  const { cycleNumber, startDate, endDate } = cycle
  if (filterBy === 'date') {
    const [from, to] = cycleNumber.split('_')
    return `${formatDate(from)} - ${formatDate(to)}`
  }

  return `Cycle ${cycleNumber} (${formatDate(startDate)} - ${formatDate(endDate)})`
}

const createProductFamilyIndex = name => {
  const Name = name.replace(/\s{1,}/g, '').toLowerCase()
  const indexList = {
    herogoogle: 1,
    heroyoutube: 2,
    herofacebook: 3,
    heroinstagram: 4,
    heroline: 5,
    herotaboola: 6,
    herolinkdin: 7,
    herochatbot: 8,
    herocalltracking: 9,
  }
  return indexList[Name]
}

const filterProducts = (data, currency, filterBy) => {
  let ops = []
  let netBudget = 0
  let budget = 0
  let spending = 0
  let clientKpi = 0
  let actualKpi = 0
  let dailyAdsBudget = 0
  let dailySpending = 0
  let clicks = 0
  let impressions = 0
  let ctr = 0
  let cpc = 0
  // let kpi = 0;
  let actualKpiUnitValue = 0
  let actualKpiUnitName = ''
  let dailyAdsBudgetRemaining = 0
  let actualCPL = 0
  let kpiLead = 0
  let estRemainingLeads = ''
  let totalEstLeads = ''
  let estAchievement = ''

  const results = data.map(item => {
    const adsBudgetRemaining = filterBy === 'date' ? null : item.budget - item.perf.spending
    const dailyAdsBudgetNew = parseFloat(Number(item.dailyAdsBudget))
    const itemActualKpiUnitValue = item.actualKpiUnitValue

    ops = item.optimizerName ? [...ops, item.optimizerName] : ops
    ops = [...new Set(ops)]

    netBudget += +item.netBudget
    budget += +item.budget
    spending += Number(item.perf.spending)
    clientKpi += +item.kpi
    // Change actualKpi to leads
    actualKpi += item.leads ? +item.leads : 0
    dailyAdsBudget += dailyAdsBudgetNew
    dailySpending += item.dailySpending
    clicks = item.perf.clicks ? Number(item.perf.clicks) + clicks : clicks
    impressions = item.perf.impressions ? Number(item.perf.impressions) + impressions : clicks
    ctr = division(clicks, impressions) * 100
    cpc = item.perf.cpc ? Number(item.perf.cpc) + cpc : cpc
    // kpi += +item.kpi;
    actualKpiUnitValue += itemActualKpiUnitValue
    actualKpiUnitName = item.actualKpiUnitName || '-'
    dailyAdsBudgetRemaining += item.dailyAdsBudgetRemaining
    actualCPL += item.actualCPL
    kpiLead = item.kpiLead ? kpiLead + Number(item.kpiLead) : kpiLead
    estRemainingLeads = item.estRemainingLeads
    totalEstLeads = item.totalEstLeads
    estAchievement = item.estAchievement

    return {
      name: item.productName,
      productComment: item.productComment,
      remark: item.remark,
      filterBy,
      id: item.productId,
      currency,
      countryId: null,
      cycleNumber: null,
      amount: null,
      actualCampaignStartDate: null,
      actualCampaignEndDate: null,
      monthlyBudget: null,
      remainingDays: null,
      totalDays: null,
      statusName: item.statusName,
      ops: item.optimizerName || null,
      clientBudget: item.netBudget || null,
      managementFee: item.managementFee || null,
      adsBudget: item.budget || null,
      spending: Number(item.perf.spending),
      adsBudgetRemaining,
      spendingPercent: percentUsed(item.perf.spending, item.budget),
      kpiName: item.kpiUnitName || null,
      clientKpi: item.kpi || null,
      actualKpi: item.leads || null,
      actualKpiUnitValue: item.actualKpiUnitValue,
      actualKpiUnitName: item.actualKpiUnitName || '-',
      kpiPercent: percentUsed(itemActualKpiUnitValue, item.kpi),
      clientBudgetCPL: division(item.budget, item.kpi),
      actualCPL: item.actualCPL,
      adsBudgetCPL: item.adsBudgetCPL,
      actualCPConv: item.actualCPConv,
      adsBudgetCPConv: item.adsBudgetCPConv,
      dailyAdsBudget: dailyAdsBudgetNew,
      dailySpending: item.dailySpending,
      dailyAdsBudgetRemaining: item.dailyAdsBudgetRemaining,
      click: Number(item.perf.clicks),
      impression: Number(item.perf.impressions),
      ctr: Number(item.perf.ctr),
      cpc: Number(item.perf.cpc),
      industry: item.industry || null,
      conversionRate: percentUsed(item.leads, item.perf.clicks),
      dayPassRate: null,
      kpiLead: item.kpiLead || null,
      percentLead: item.leadPercentage,
      estRemainingLeads: '-',
      totalEstLeads: '-',
      estAchievement: '-',
    }
  })
  return {
    data: results,
    options: {
      ops,
      netBudget,
      adsBudget: budget,
      spending,
      clientKpi,
      actualKpi,
      dailyAdsBudget,
      dailySpending,
      clicks,
      impressions,
      ctr,
      cpc,
      // kpi,
      actualKpiUnitValue,
      actualKpiUnitName,
      dailyAdsBudgetRemaining,
      actualCPL,
      kpiLead,
      estRemainingLeads,
      totalEstLeads,
      estAchievement,
    },
  }
}

const filterProductFamilys = (data, productType, currency, filterBy) => {
  const groupByChannelTypeId = data.reduce((res, item) => {
    const response = res
    response[item.channelTypeId] = res[item.channelTypeId] || []
    response[item.channelTypeId] = [...res[item.channelTypeId], { ...item, currency }]
    return response
  }, {})
  let cycleOps = []
  let cycleNetBudget = 0
  let cycleAdsBudget = 0
  let cycleSpending = 0
  let cycleClientKpi = 0
  let cycleActualKpi = 0
  let cycleDailyAdsBudget = 0
  let cycleDailySpending = 0
  let cycleClicks = 0
  let cycleImpressions = 0
  let cycleCtr = 0
  let cycleCpc = 0
  // let cycleKpi = 0;
  let cycleActualKpiUnitValue = 0
  let cycleActualKpiUnitName = ''
  let cycleDailyAdsBudgetRemaining = 0
  let cycleActualCPL = 0
  let cycleKpiLead = 0
  let cycleEstRemainingLeads = ''
  let cycleTotalEstLeads = ''
  let cycleEstAchievement = ''

  const results = Object.keys(groupByChannelTypeId).map(key => {
    const firstChild = groupByChannelTypeId[key][0]
    const productTypeInfor = productType.filter(({ id }) => id === key)[0]
    const { data: childs, options } = filterProducts(groupByChannelTypeId[key], currency, filterBy)
    const ops = options.ops ? options.ops.join(', ') : null
    cycleOps = [...new Set([...cycleOps, ...options.ops])]
    cycleNetBudget += +options.netBudget
    cycleAdsBudget += +options.adsBudget
    cycleSpending += options.spending
    cycleClientKpi += options.clientKpi
    cycleActualKpi += options.actualKpi
    cycleDailyAdsBudget += options.dailyAdsBudget
    cycleDailySpending += options.dailySpending
    cycleClicks += options.clicks
    cycleImpressions += +options.impressions
    cycleCtr = division(cycleClicks, cycleImpressions) * 100
    cycleCpc += +options.cpc
    // cycleKpi += options.kpi;
    cycleActualKpiUnitName = options.actualKpiUnitName
    cycleActualKpiUnitValue += options.actualKpiUnitValue
    cycleDailyAdsBudgetRemaining += options.dailyAdsBudgetRemaining
    cycleActualCPL += options.actualCPL
    cycleKpiLead += options.kpiLead
    cycleEstRemainingLeads = options.estRemainingLeads
    cycleTotalEstLeads = options.totalEstLeads
    cycleEstAchievement = options.estAchievement

    return {
      name: productTypeInfor.channelTypeName,
      filterBy,
      id: productTypeInfor.id,
      index: createProductFamilyIndex(productTypeInfor.channelTypeName),
      currency,
      countryId: null,
      cycleNumber: null,
      amount: null,
      actualCampaignStartDate: null,
      actualCampaignEndDate: null,
      monthlyBudget: null,
      remainingDays: null,
      totalDays: null,
      statusName: firstChild.statusName,
      // kpi: options.kpi,
      ops,
      clientBudget: options.netBudget,
      managementFee: firstChild.managementFee || null,
      adsBudget: options.adsBudget,
      spending: options.spending,
      adsBudgetRemaining: filterBy === 'date' ? null : options.adsBudget - options.spending,
      spendingPercent: percentUsed(options.spending, options.adsBudget),
      kpiName: firstChild.kpiUnitName || null,
      clientKpi: options.clientKpi,
      actualKpi: options.actualKpi,
      actualKpiUnitValue: options.actualKpiUnitValue,
      actualKpiUnitName: options.actualKpiUnitName.length > 0 ? '*' : '-',
      kpiPercent: percentUsed(options.actualKpiUnitValue, options.clientKpi),
      clientBudgetCPL: division(options.adsBudget, options.clientKpi),
      actualCPL: division(options.spending, options.actualKpi),
      adsBudgetCPL: division(options.adsBudget, options.actualKpi),
      actualCPConv: division(options.spending, options.actualKpiUnitValue),
      adsBudgetCPConv: division(options.adsBudget, options.clientKpi),
      dailyAdsBudget: options.dailyAdsBudget,
      dailySpending: options.dailySpending,
      dailyAdsBudgetRemaining: options.dailyAdsBudgetRemaining,
      click: options.clicks,
      impression: options.impressions,
      ctr: options.ctr,
      cpc: options.cpc,
      industry: firstChild.industry || null,
      conversionRate: percentUsed(options.actualKpi, options.clicks),
      dayPassRate: null,
      kpiLead: options.kpiLead,
      percentLead: percentUsed(options.actualKpi, options.kpiLead),
      estRemainingLeads: '-',
      totalEstLeads: '-',
      estAchievement: '-',
      childs,
    }
  }).sort((a, b) => a.index - b.index)

  return {
    data: results,
    options: {
      cycleOps,
      cycleNetBudget,
      cycleAdsBudget,
      cycleSpending,
      cycleClientKpi,
      cycleActualKpi,
      cycleDailyAdsBudget,
      cycleDailySpending,
      cycleClicks,
      cycleImpressions,
      cycleCtr,
      cycleCpc,
      // cycleKpi,
      cycleActualKpiUnitValue,
      cycleActualKpiUnitName,
      cycleDailyAdsBudgetRemaining,
      cycleActualCPL,
      cycleKpiLead,
      cycleEstRemainingLeads,
      cycleTotalEstLeads,
      cycleEstAchievement,
    },
  }
}

const filterChannelCycles = (ChannelCycles, CId, ChannelTypes, currency, FilterBy) => {
  const cc = ChannelCycles.filter(({ campaignId }) => campaignId === CId)
  const groupDataByCycleNumber = cc.reduce((res, item) => {
    const key = `cycle${item.cycleNumber}`
    const response = res
    response[key] = res[key] || []
    response[key] = [...res[key], { ...item, currency }]
    return response
  }, {})

  let campaignOps = []
  let campaignBudget = 0
  let campaignAdsBudget = 0
  let campaignSpending = 0
  let campaignClientKpi = 0
  let campaignActualKpi = 0
  let campaignDailyAdsBudget = 0
  let campaignDailySpending = 0
  let campaignClicks = 0
  let campaignImpressions = 0
  let campaignCtr = 0
  let campaignCpc = 0
  // let campaignKpi = 0;
  let campaignCycleActualKpiUnitValue = 0
  let campaignCycleActualKpiUnitName = ''
  let campaignDailyAdsBudgetRemaining = 0
  let campaignActualCPL = 0
  let campaignKpiLead = 0

  const results = Object.keys(groupDataByCycleNumber).map(key => {
    const firstChild = groupDataByCycleNumber[key][0]
    const { data: childs, options } = filterProductFamilys(groupDataByCycleNumber[key], ChannelTypes, currency, FilterBy)
    const ops = options.cycleOps ? options.cycleOps.join(', ') : null
    const netBudget = options.cycleNetBudget

    campaignOps = [...new Set([...campaignOps, ...options.cycleOps])]
    campaignBudget += netBudget
    campaignAdsBudget += options.cycleAdsBudget
    campaignSpending += options.cycleSpending
    campaignClientKpi += options.cycleClientKpi
    campaignActualKpi += options.cycleActualKpi
    campaignDailyAdsBudget += options.cycleDailyAdsBudget
    campaignDailySpending += options.cycleDailySpending
    campaignClicks += options.cycleClicks
    campaignImpressions += options.cycleImpressions
    campaignCtr = division(campaignClicks, campaignImpressions) * 100
    campaignCpc += options.cycleCpc
    // campaignKpi += options.cycleKpi;
    campaignCycleActualKpiUnitValue += options.cycleActualKpiUnitValue
    campaignCycleActualKpiUnitName = options.cycleActualKpiUnitName
    campaignDailyAdsBudgetRemaining += options.cycleDailyAdsBudgetRemaining
    campaignActualCPL += options.cycleActualCPL
    campaignKpiLead += options.cycleKpiLead

    const item = {
      name: generateCycleName(firstChild, FilterBy),
      id: firstChild.id,
      currency: firstChild.currency || null,
      countryId: firstChild.countryId || null,
      cycleNumber: FilterBy === 'cycle' ? firstChild.cycleNumber : null,
      amount: firstChild.amount || null,
      actualCampaignStartDate: firstChild.startDate,
      actualCampaignEndDate: firstChild.endDate,
      managementFee: firstChild.managementFee || null,
      monthlyBudget: firstChild.monthlyBudget || null,
      remainingDays: FilterBy === 'date' ? null : firstChild.remainingDays,
      totalDays: firstChild.totalDays,
      statusName: firstChild.statusName,
      // kpi: options.cycleKpi,
      ops,
      clientBudget: netBudget,
      adsBudget: options.cycleAdsBudget,
      spending: options.cycleSpending,
      adsBudgetRemaining: FilterBy === 'date' ? null : options.cycleAdsBudget - options.cycleSpending,
      spendingPercent: percentUsed(options.cycleSpending, options.cycleAdsBudget),
      kpiName: null,
      clientKpi: options.cycleClientKpi,
      actualKpi: options.cycleActualKpi,
      actualKpiUnitValue: options.cycleActualKpiUnitValue,
      actualKpiUnitName: options.cycleActualKpiUnitName.length > 0 ? '*' : '-',
      kpiPercent: percentUsed(options.cycleActualKpiUnitValue, options.cycleClientKpi),
      clientBudgetCPL: division(options.cycleAdsBudget, options.cycleClientKpi),
      actualCPL: division(options.cycleSpending, options.cycleActualKpi),
      adsBudgetCPL: division(options.cycleAdsBudget, options.cycleActualKpi),
      actualCPConv: division(options.cycleSpending, options.cycleActualKpiUnitValue),
      adsBudgetCPConv: division(options.cycleAdsBudget, options.cycleClientKpi),
      dailyAdsBudget: options.cycleDailyAdsBudget,
      dailySpending: options.cycleDailySpending,
      dailyAdsBudgetRemaining: options.cycleDailyAdsBudgetRemaining,
      click: options.cycleClicks,
      impression: options.cycleImpressions,
      ctr: options.cycleCtr,
      cpc: options.cycleCpc,
      industry: firstChild.industry || null,
      filterBy: FilterBy,
      realCycleData: firstChild.cycleNumber,
      conversionRate: percentUsed(options.cycleActualKpi, options.cycleClicks),
      dayPassRate: firstChild.dayPassRate,
      kpiLead: options.cycleKpiLead,
      percentLead: percentUsed(options.cycleActualKpi, options.cycleKpiLead),
      estRemainingLeads: firstChild.statusName === 'Active' ? options.cycleEstRemainingLeads : '-',
      totalEstLeads: firstChild.statusName === 'Active' ? options.cycleTotalEstLeads : '-',
      estAchievement: firstChild.statusName === 'Active' ? parseFloat(options.cycleEstAchievement).toFixed(2) : '-',
      childs,
    }
    return item
  }).sort((a, b) => {
    const { filterBy } = a
    if (filterBy === 'cycle') return b.cycleNumber - a.cycleNumber
    const [, _a] = a.realCycleData.split('_')
    const [, _b] = b.realCycleData.split('_')

    return new Date(_b).getTime() - new Date(_a).getTime()
  })
  return {
    data: results,
    options: {
      campaignOps,
      campaignBudget,
      campaignAdsBudget,
      campaignSpending,
      campaignActualKpi,
      campaignClientKpi,
      campaignDailyAdsBudget,
      campaignDailySpending,
      campaignClicks,
      campaignImpressions,
      campaignCtr,
      campaignCpc,
      // campaignKpi,
      campaignCycleActualKpiUnitValue,
      campaignCycleActualKpiUnitName,
      campaignDailyAdsBudgetRemaining,
      campaignActualCPL,
      campaignKpiLead,
    },
  }
}

export const transformApiData = res => {
  // ถ้าไม่มีข้อมูล ให้หยุดการทำงาน
  if (res.length === 0) return []

  const {
    campaigns,
    channel_cycles,
    channel_types,
    filterBy,
  } = res

  const results = campaigns.map(item => {
    const { id, currency } = item
    const { data: childs, options } = filterChannelCycles(channel_cycles, id, channel_types, currency, filterBy)
    const ops = options.campaignOps ? options.campaignOps.join(', ') : null

    return {
      name: item.name,
      id: item.campaignId,
      filterBy,
      currency: item.currency || null,
      countryId: item.countryId || null,
      cycleNumber: '-',
      amount: item.amount || null,
      actualCampaignStartDate: item.actualCampaignStartDate,
      actualCampaignEndDate: item.actualCampaignEndDate,
      managementFee: item.managementFee || null,
      monthlyBudget: item.monthlyBudget || null,
      remainingDays: filterBy === 'date' ? null : item.remainingDays,
      totalDays: item.totalDays,
      statusName: item.statusName,
      // kpi: options.campaignKpi,
      ops,
      clientBudget: options.campaignBudget,
      adsBudget: options.campaignAdsBudget,
      spending: options.campaignSpending,
      adsBudgetRemaining: filterBy === 'date' ? null : options.campaignAdsBudget - options.campaignSpending,
      spendingPercent: percentUsed(options.campaignSpending, options.campaignAdsBudget),
      kpiName: item.kpiUnitName || null,
      clientKpi: options.campaignClientKpi,
      actualKpi: options.campaignActualKpi,
      actualKpiUnitValue: options.campaignCycleActualKpiUnitValue,
      actualKpiUnitName: options.campaignCycleActualKpiUnitName.length > 0 ? '*' : '-',
      kpiPercent: percentUsed(options.campaignCycleActualKpiUnitValue, options.campaignClientKpi),
      clientBudgetCPL: division(options.campaignAdsBudget, options.campaignClientKpi),
      actualCPL: division(options.campaignSpending, options.campaignActualKpi),
      adsBudgetCPL: division(options.campaignAdsBudget, options.campaignActualKpi),
      actualCPConv: division(options.campaignSpending, options.campaignCycleActualKpiUnitValue),
      adsBudgetCPConv: division(options.campaignAdsBudget, options.campaignClientKpi),
      dailyAdsBudget: options.campaignDailyAdsBudget,
      dailySpending: options.campaignDailySpending,
      dailyAdsBudgetRemaining: filterBy === 'date' ? null : options.campaignDailyAdsBudgetRemaining,
      click: options.campaignClicks,
      impression: options.campaignImpressions,
      ctr: null, // options.campaignCtr
      cpc: null, // options.campaignCpc
      industry: item.industry || null,
      conversionRate: percentUsed(options.campaignActualKpi, options.campaignClicks),
      dayPassRate: null,
      kpiLead: options.campaignKpiLead,
      percentLead: percentUsed(options.campaignActualKpi, options.campaignKpiLead),
      estRemainingLeads: item.estRemainingLeads,
      totalEstLeads: item.totalEstLeads,
      estAchievement: item.estAchievement,
      childs,
    }
  })

  return results
}

export const tableHeads = [
  {
    text: 'Campaign Name',
    key: 'name',
    required: true,
    fixed: true,
    description: '<b>Campaign Name / Channel Name.</b>',
    sortable: true,
    datatype: 'string',
  },
  {
    text: 'Client Product Name',
    key: 'remark',
    datatype: 'string',
  },
  {
    text: 'Solution Plan Comment',
    key: 'productComment',
    datatype: 'string',
  },
  {
    text: 'Status',
    key: 'statusName',
    description: 'Status of current cycle.',
    sortable: true,
    datatype: 'string',
  },
  {
    text: 'PMKT',
    key: 'ops',
    // sortable: true
  },
  {
    text: 'Start Date',
    key: 'actualCampaignStartDate',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Campaign Start Date.</li>
        <li>Cycle: Campaign Start Date.</li>
      </ul>
    `,
    sortable: true,
    datatype: 'date',
  },
  {
    text: 'End Date',
    key: 'actualCampaignEndDate',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Campaign End Date.</li>
        <li>Cycle: Campaign End Date.</li>
      </ul>
    `,
    sortable: true,
    datatype: 'date',
  },
  {
    text: 'Net Budget',
    key: 'clientBudget',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Campaign Budget.</li>
        <li>Cycle: Net Budget from current cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: '% Fee',
    key: 'managementFee',
    description: 'Service fee which Heroleads receive from client apart from other service.',
    sortable: true,
  },
  {
    text: 'Ads Budget',
    key: 'adsBudget',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Campaign Budget - Fee%.</li>
        <li>Cycle: Ads Budget from current cycle -> [Client Budget - Fee%].</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Spending',
    key: 'spending',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Total Spending of Campaign.</li>
        <li>Cycle: current Cycle Spending.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Ads Budget Remaining',
    key: 'adsBudgetRemaining',
    sortable: true,
  },
  {
    text: '% Spending',
    key: 'spendingPercent',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Percentage of Spending of Campaign.</li>
        <li>Cycle: Percentage of current cycle -> [(Ads Budget / Net Budget) x 100].</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'KPI Name',
    key: 'kpiName',
    description: 'Display KPI Unit from Media Plan of specific cycle.',
    sortable: true,
  },
  {
    text: 'KPI', // Change from Client KPI to KPI
    key: 'clientKpi',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Total KPI of Campaign.</li>
        <li>Cycle: KPI of current cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Result', // CHange from Actual KPI to Result
    key: 'actualKpiUnitValue',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Total Actual Leads of Campaign.</li>
        <li>Cycle: Actual leads of curren cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Target CPR',
    key: 'adsBudgetCPConv',
    description: 'Target cost per result.',
    sortable: true,
  },
  {
    text: 'Actual CPR',
    key: 'actualCPConv',
    description: 'Actual cost per result.',
    sortable: true,
  },
  {
    text: '% Result', // Change from % KPI to % Result
    key: 'kpiPercent',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Percentage of Actual Leads of Campaign.</li>
        <li>Cycle: Percentage of current cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Leads',
    key: 'actualKpi',
    sortable: true,
  },
  {
    text: 'Target CPL', // Change from Ads Budget CPL to Target CPL
    key: 'adsBudgetCPL',
    description: `
      <ul class="pl-1">
        <li>FB and IG products, Only LG, LG_RT, CVS, CVS_RT will have results calculated.</li>
        <li>Other products KPI = "Leads" will have results calculated.</li>
        <li>Other products that do not meet the above conditions will always display 0.</li>
      </ul>
      <p class="mb-0">Additionally, if either the Target or Actual CPL is calculated as 0, both columns will display 0.</p>
    `,
    sortable: true,
  },
  {
    text: 'Actual CPL',
    key: 'actualCPL',
    description: `
      <ul class="pl-1">
        <li>FB and IG products, Only LG, LG_RT, CVS, CVS_RT will have results calculated.</li>
        <li>Other products KPI = "Leads" will have results calculated.</li>
        <li>Other products that do not meet the above conditions will always display 0.</li>
      </ul>
      <p class="mb-0">Additionally, if either the Target or Actual CPL is calculated as 0, both columns will display 0.</p>
    `,
    sortable: true,
  },
  {
    text: 'Daily Ads Budget',
    key: 'dailyAdsBudget',
    sortable: true,
  },
  {
    text: 'Daily Spending',
    key: 'dailySpending',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Daily Spending of campaign.</li>
        <li>Cycle: Daily Spending of current cycle -> [Spending / Total Day].</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Daily Ads Budget Remaining',
    key: 'dailyAdsBudgetRemaining',
    sortable: true,
  },
  {
    text: 'Remain Days', // Change from Remaining Days to Remain Days
    key: 'remainingDays',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Days remaining of campaign.</li>
        <li>Cycle: Days remaining of cycle -> [End Date - Current Date].</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Click',
    key: 'click',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Total Clicks performance of Campaign.</li>
        <li>Cycle: Total Clicks of current cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'Impression',
    key: 'impression',
    description: `
      <ul class="pl-1 mb-0">
        <li>Campaign: Total Impressions performance of Campaign.</li>
        <li>Cycle: Total Impressions of current cycle.</li>
      </ul>
    `,
    sortable: true,
  },
  {
    text: 'CTR',
    key: 'ctr',
    description: 'Click Throught rate -> [licks / impressions].',
    sortable: true,
  },
  {
    text: 'CPC',
    key: 'cpc',
    description: 'Cost Per Clicks -> [Clicks / Spending].',
    sortable: true,
  },
  {
    text: 'CVR',
    key: 'conversionRate',
    description: 'Conversion Rate',
    sortable: true,
  },
  {
    text: 'Cycle Number',
    key: 'cycleNumber',
    alignContent: 'center',
    sortable: true,
  },
  {
    text: 'Total Days',
    key: 'totalDays',
    sortable: true,
  },
  {
    text: 'Actual KPI Unit',
    key: 'actualKpiUnitName',
    sortable: true,
  },
  {
    text: '% Day Pass', // Change from Day Pass Rate to % Day Pass
    key: 'dayPassRate',
    description: 'Day Pass Rate',
    sortable: true,
  },
  {
    text: 'Industry',
    key: 'industry',
    description: 'Industry of Accounts e.g Automotive, Real Estate.',
    sortable: true,
    datatype: 'string',
  },
  {
    text: 'Currency',
    key: 'currency',
    alignContent: 'center',
    sortable: true,
  },
  {
    text: 'KPI Lead',
    key: 'kpiLead',
    alignContent: 'center',
    sortable: true,
  },
  {
    text: '% Lead',
    key: 'percentLead',
    alignContent: 'center',
    sortable: true,
  },
  {
    text: 'Est Remaining Leads',
    key: 'estRemainingLeads',
    alignContent: 'right',
    sortable: true,
  },
  {
    text: 'Total Est Leads',
    key: 'totalEstLeads',
    alignContent: 'right',
    sortable: true,
  },
  {
    text: '% Est Achievement',
    key: 'estAchievement',
    alignContent: 'right',
    sortable: true,
  },
  // {
  //   text: 'Amount',
  //   key: 'amount',
  //   description: '[Camaign] Total Budget (all cycles)',
  //   alignContent: 'left',
  //   sortable: true,
  // },
]

export const doubleRaf = callback => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback)
  })
}
