import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
    sourceId: '',
    sourceType: '',
  },

  getters: {
    getOptions(state) {
      return state.data.map(item => ({
        text: `${item.name} - ${item.remain_amount}`,
        value: item.id,
        originalAmount: item.original_amount,
        remainAmount: item.remain_amount,
      }))
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
    setSourceId(state, payload) {
      state.sourceId = payload
    },
    setSourceType(state, payload) {
      state.sourceType = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const url = `/dropdown-options/wallet-budget-options?perPage=10000&page=1&orderByColumn=name&orderByDirection=asc&sourceId=${context.state.sourceId}&sourceType=${context.state.sourceType}`
      const data = await DropdownService.loadData(url, 2)
      await context.commit('setData', data.data)
    }, // fetchOptions
  }, // actions
}
