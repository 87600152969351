import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    data: [],
  },

  getters: {
    getOptions(state) {
      return state.data.map(item => ({
        text: item.name,
        value: item.id,
        campaign_status_id: item.campaign_status_id,
      }))
    },
    getIsLoading(state) {
      return state.isLoading
    },
  },

  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    },
    setData(state, payload) {
      state.data = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      context.commit('setIsLoading', true)

      const url = '/dropdown-options/herovision-campaign-options?orderByColumn=name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 1)
      await context.commit('setData', data.data)

      context.commit('setIsLoading', false)
    }, // fetchOptions
  }, // actions
}
