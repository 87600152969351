import moment from 'moment-timezone'
import DropdownService from '@/services/DropdownService'
import DataFormatService from '@/services/DataFormatService'

async function fetchData(context, page, lastPage) {
  if (lastPage >= page) {
    const url = `/dropdown-options/wallet-budget-options?perPage=50&page=${page}&orderByColumn=name&orderByDirection=asc&sourceId=${context.state.sourceId}&sourceType=${context.state.sourceType}`
    const data = await DropdownService.loadData(url, 2)
    await context.commit('setData', data.data)

    await fetchData(context, page + 1, data.last_page)
  }
}

export default {
  namespaced: true,
  state: {
    loadedTime: null,
    doUpdate: false,
    data: [],
    options: [],
    sourceId: '',
    sourceType: '',
  },

  getters: {
    getOptions(state) {
      return state.options
    },
    getRemainAmount: state => id => {
      const wallet = state.data.find(object => object.id === id)
      return wallet.remain_amount
    },
    getWalletName: state => id => {
      const wallet = state.data.find(object => object.id === id)
      return wallet.name
    },
  },

  mutations: {
    clearData(state) {
      state.data = []
    },
    setLoadedTime(state, payload) {
      state.loadedTime = payload
    },
    setDoUpdate(state, payload) {
      state.doUpdate = payload
    },
    setData(state, payload) {
      state.data = [...state.data, ...payload]
    },
    setOptions(state, payload) {
      state.options = payload
    },
    setSourceId(state, payload) {
      state.sourceId = payload
    },
    setSourceType(state, payload) {
      state.sourceType = payload
    },
  },

  actions: {
    async fetchOptions(context) {
      const { loadedTime, doUpdate } = context.state

      if (doUpdate || !loadedTime || moment().diff(loadedTime, 'minute') > 1) {
        context.commit('setDoUpdate', false)
        context.commit('setLoadedTime', moment().tz('Asia/Bangkok').format())
        context.commit('clearData')
        await fetchData(context, 1, 1)
      }

      const { data } = context.state
      const options = data.map(item => ({
        text: `${item.name} { ${DataFormatService.formatMoney(item.remain_amount)} }`,
        value: item.id,
        originalAmount: item.original_amount,
        remainAmount: item.remain_amount,
      }))

      context.commit('setOptions', options)
    }, // fetchOptions
  }, // actions
}
