/* ------------------------------------------------------------------------------------------------------------------ */
/* --- This one used in page settings/roles ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

import DropdownService from '@/services/DropdownService'

export default {
  namespaced: true,
  state: {
    data: [],
    permissionIds: [],
  },

  getters: {
    getOptions(state) {
      const { data, permissionIds } = state
      return data.filter(item => !permissionIds.includes(item.id) && item.is_active).map(item => ({
        text: item.name,
        value: item.name,
      }))
    },
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    },
    setPermissionIds(state, payload) {
      state.permissionIds = payload.map(permission => permission.id)
    },
  },

  actions: {
    async fetchOptions(context) {
      const url = '/permissions?perPage=10000&page=1&orderByColumn=name&orderByDirection=asc'
      const data = await DropdownService.loadData(url, 2)
      await context.commit('setData', data.data)
    }, // fetchOptions
  }, // actions
}
