import User from './User'

export default class StaffAssignment {
  constructor(item = {}) {
    return {
      account_manager: new User(item.account_manager),
      optimizer_1: new User(item.optimizer_1),
      optimizer_2: new User(item.optimizer_2),
      optimizer_3: new User(item.optimizer_3),
      optimizer_4: new User(item.optimizer_4),
      optimizer_5: new User(item.optimizer_5),
      copy_writer_1: new User(item.copy_writer_1),
      copy_writer_2: new User(item.copy_writer_2),
      copy_writer_3: new User(item.copy_writer_3),
      graphic_designer_1: new User(item.graphic_designer_1),
      graphic_designer_2: new User(item.graphic_designer_2),
      graphic_designer_3: new User(item.graphic_designer_3),
      system_support: new User(item.system_support),
      data_analyst: new User(item.data_analyst),
      influencer_planner: new User(item.influencer_planner),
      digital_associate: new User(item.digital_associate),
      sales: item.sales || '',
      sales_name: item.sales_name || '',
      viewers: item.viewers || [],
      viewers_name: item.viewers_name || '',
      seo_members: item.seo_members || [],
      seo_members_name: item.seo_members_name || '',
    }
  }
}
