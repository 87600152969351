export default [
  {
    path: '/settings/clients',
    name: 'settings-clients-list',
    component: () => import('@/views/heroai/settings/clients/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
          to: '/settings/clients',
        },
      ],
      resource: 'Clients',
      action: 'Access',
    },
  },

  {
    path: '/settings/permissions',
    name: 'settings-permissions-list',
    component: () => import('@/views/heroai/settings/permissions/PermissionList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Permissions',
      breadcrumb: [
        {
          text: 'Permissions',
          active: true,
          to: '/settings/permissions',
        },
      ],
      resource: 'Permissions',
      action: 'Access',
    },
  },

  {
    path: '/settings/roles',
    name: 'settings-roles-list',
    component: () => import('@/views/heroai/settings/roles/RoleList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
          to: '/settings/roles',
        },
      ],
      resource: 'Roles',
      action: 'Access',
    },
  },

  {
    path: '/settings/users',
    name: 'settings-users-list',
    component: () => import('@/views/heroai/settings/users/UserList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
          to: '/settings/users',
        },
      ],
      resource: 'Users',
      action: 'Access',
    },
  },
]
