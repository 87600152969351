/* Auth */
export const PURGE_AUTH = 'purgeAuth'
export const RESET_AUTH = 'resetAuth'
export const SET_AUTH = 'setAuth'
export const SET_TOKEN = 'setToken'

/* Channel */
export const RESET_PERFROMANCES = 'resetPerfromances'

/* Toggle */
export const RESET_ALL_TOGGLE = 'resetAllToggle'
export const SET_MESSAGE_MODAL = 'setMessageModal'
export const SET_TABLE_DIALOG = 'setTableDialog'
export const TOGGLE_PAGE_IS_LOADING = 'togglePageIsLoading'
export const UPDATE_FILTER_DATAS = 'updateFilterDatas'
