export default [
  {
    path: '/spam-management/leads/:campaignId?',
    name: 'spam-management-leads',
    component: () => import('@/views/spam-management/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Spam Management',
      breadcrumb: [
        {
          text: 'Spam Management',
          active: true,
          to: 'spam-management/leads',
        },
      ],
      resource: 'Spam Management',
      action: 'Access',
    },
  },
  {
    path: '/blacklist-leads',
    name: 'blacklist-leads',
    component: () => import('@/views/blacklist-leads/BlacklistLeadList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Blacklist Leads',
      breadcrumb: [
        {
          text: 'Blacklist Leads',
          active: true,
          to: 'blacklist-leads',
        },
      ],
      resource: 'Blacklist Leads',
      action: 'Access',
    },
  },
]
