import authentication from '@/router/heroai/authentications'
import settings from '@/router/heroai/settings'
import masters from '@/router/heroai/masters'
import productOfferings from '@/router/heroai/product-offering'
import wallets from '@/router/heroai/wallets'
import campaign from '@/router/heroai/campaign'
import channelperformance from '@/router/heroai/channelperformance'

export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
          to: '/home',
        },
      ],
    },
  },

  {
    path: '/accounts',
    name: 'accounts-list',
    component: () => import('@/views/heroai/accounts/list/AccountList.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
          to: '/accounts',
        },
      ],
      resource: 'Accounts',
      action: 'Access',
    },
  },

  {
    path: '/accounts/:id',
    name: 'accounts-detail',
    component: () => import('@/views/heroai/accounts/detail/AccountDetail.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: '/accounts',
        },
        {
          text: 'Detail',
          active: true,
          to: '/accounts',
        },
      ],
      resource: 'Accounts',
      action: 'Access',
    },
  },

  {
    path: '/opportunities',
    name: 'opportunities-list',
    component: () => import('@/views/heroai/opportunities/index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Opportunities',
      breadcrumb: [
        {
          text: 'Opportunities',
          active: true,
          to: '/opportunities',
        },
      ],
      resource: 'Opportunities',
      action: 'Access',
    },
  },

  {
    path: '/opportunities/:id',
    name: 'opportunities-detail',
    component: () => import('@/views/heroai/opportunities/view.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Opportunities',
      breadcrumb: [
        {
          text: 'Opportunities',
          active: false,
          to: '/opportunities',
        },
        {
          text: 'Detail',
          active: true,
          to: '/opportunities',
        },
      ],
      resource: 'Opportunities',
      action: 'Access',
    },
  },
  {
    path: '/creative-briefing/:oppId/:id',
    name: 'creative-briefing',
    component: () => import('@/views/heroai/creative-briefing/CreativeBriefing.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/creative-briefing/:oppId/:id/thank-you',
    name: 'creative-briefing-thank-you',
    component: () => import('@/views/heroai/creative-briefing/ThankYou.vue'),
    meta: {
      layout: 'full',
    },
  },
  ...campaign,
  ...wallets,
  ...authentication,
  ...productOfferings,
  ...settings,
  ...masters,
  ...channelperformance,
]
