import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import ChannelService from '@/services/ChannelService'
import filters from '@/plugins/filters'
import CategoryDropdownFactory from '@/factories/Campaign/CategoryDropdown'
import { RESET_PERFROMANCES, UPDATE_FILTER_DATAS } from '../mutation-types'
// import { FETCH_PERFROMANCES } from '../action-types'

const FETCH_PERFROMANCES = 'fetchPerfromances'

const MAP_CHANNEL_TYPES = {
  AdWords: 'Google',
  YouTube: 'YouTube',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  'Call Tracking': 'Call Tracking',
  Direct: 'Landing Page',
  Messenger: 'Messenger',
  'Line Ads': 'Line Ads',
  Taboola: 'Taboola',
  Linkedin: 'Linkedin',
}
const MAP_CAMPAIGN_TYPES = {
  Google: 'Search',
  AdWords: 'Search',
  YouTube: 'Search',
  Facebook: 'CONVERSIONS',
  Instagram: 'CONVERSIONS',
}
const sumByKey = (parent, key) => {
  // eslint-disable-next-line no-underscore-dangle
  const sumResult = parent._children.reduce((total, child) => total + Number(child[key]), 0)
  // eslint-disable-next-line no-param-reassign
  parent[key] = sumResult
}
const sumByKeys = (parent, keys = []) => {
  keys.forEach(key => {
    sumByKey(parent, key)
  })
}
const dateFormat = (date, format = 'YYYY/MM/DD') => {
  if (!date) return ''

  const newDate = (typeof date === 'string') ? date.replace(' ', 'T') : date
  const { $moment } = Vue.prototype
  return $moment(newDate).format(format)
}

export default {
  namespaced: true,
  state: {
    autoLoad: {
      loading: false,
      loader: ChannelService.list,
      params: {},
      counter: 0,
      emptyMap: {},
    },
    channelPerformances: {},
    filterDatas: {
      account_manager: [],
      country: [],
      kpi: [],
      objective: [],
      optimizer: [],
      product_types: [],
      products: [],
    },
  },
  mutations: {
    [RESET_PERFROMANCES](state, params) {
      state.autoLoad.params = params
      state.autoLoad.counter = 0
      state.channelPerformances = {}
    },
    [UPDATE_FILTER_DATAS](state, params) {
      state.filterDatas = params
    },
  },
  actions: {
    async [FETCH_PERFROMANCES]({ commit }, date) {
      commit(RESET_PERFROMANCES, { date })
    },
  },
  getters: {
    transformedPerformances(state, getters) {
      const performances = getters.getArray('channelPerformances')

      const mappedPerformances = performances.map(campaign => {
        const {
          campaignId, currency, industry, channels,
        } = campaign

        let campaignData = campaign

        campaignData.currency = currency || 'THB'
        campaignData.shouldExport = false
        campaignData.hasChildren = true
        // eslint-disable-next-line no-underscore-dangle
        campaignData._showChildren = false
        // eslint-disable-next-line no-underscore-dangle
        campaignData._children = channels.map(channel => {
          const {
            channelTypeFullName, startDate, endDate, netBudget, budget, kpi, totalDays, spendingDays, analytic, actualKpi,
          } = channel
          const channelData = channel
          channelData.campaignId = campaignId
          channelData.currency = currency || 'THB'
          channelData.startDate = dateFormat(startDate)
          channelData.endDate = dateFormat(endDate)
          channelData.industry = industry
          channelData.channelTypeNameMapped = MAP_CHANNEL_TYPES[channelTypeFullName]
          channelData.campaignTypeNameMapped = MAP_CAMPAIGN_TYPES[channelTypeFullName]
          channelData.adsBudgetRemaining = Number(budget) ? (budget - analytic.spending) : null
          channelData.spendingPercent = Number(budget) ? filters.number(analytic.spending / (budget * 100)) : null

          const dailyAdsBudget = totalDays ? filters.number(budget / totalDays) : null
          const dailySpending = spendingDays ? filters.number(analytic.spending / spendingDays) : null
          const dailyAdsBudgetRemaining = (dailyAdsBudget === null || dailySpending === null) ? null : (dailyAdsBudget - dailySpending)
          channelData.dailyAdsBudget = dailyAdsBudget
          channelData.dailySpending = dailySpending
          channelData.dailyAdsBudgetRemaining = dailyAdsBudgetRemaining

          channelData.kpiPercent = (actualKpi && Number(kpi)) ? filters.number(actualKpi / (kpi * 100)) : null
          channelData.clientBudgetCpl = actualKpi ? filters.number(netBudget / actualKpi) : null
          channelData.actualCpl = actualKpi ? filters.number(budget / actualKpi) : null

          const { ctr, cpc } = channel.analytic
          channelData.analytic.ctr = filters.number(ctr)
          channelData.analytic.cpc = filters.number(cpc)

          return {
            ...channel,
            ...channel.analytic,
          }
        })

        sumByKeys(campaign, [
          'netBudget', 'budget', 'spending', 'adsBudgetRemaining',
          'dailyAdsBudget', 'dailySpending', 'dailyAdsBudgetRemaining', 'kpi', 'actualKpi',
          'clientBudgetCpl', 'actualCpl', 'totalCpl', 'impressions', 'clicks',
        ])
        const {
          actualCampaignEndDate, actualCampaignStartDate, budget, kpi, actualKpi, spending, impressions, clicks,
        } = campaign
        campaignData = campaign
        campaignData.endDate = dateFormat(actualCampaignEndDate)
        campaignData.startDate = dateFormat(actualCampaignStartDate)
        campaignData.spendingPercent = budget ? filters.number(spending / (budget * 100)) : null
        campaignData.kpiPercent = kpi ? filters.number(actualKpi / (kpi * 100)) : null
        campaignData.ctr = impressions ? filters.number(clicks / (impressions * 100)) : null
        campaignData.cpc = clicks ? filters.number(spending / clicks) : null

        return campaign
      })

      return mappedPerformances
    },

    filterDatas(state) {
      const {
        kpi,
        objective,
        product_types: productFamily,
        products,
        optimizer: optimizers,
        account_manager: ams,
        country: countryData,
        campaign_status: campaignStatusData,
      } = state.filterDatas

      const KPIOptions = CategoryDropdownFactory.createFromJsonArray(kpi)
      const ObjectiveOptions = CategoryDropdownFactory.createFromJsonArray(objective)
      const CampaignStatusOptions = CategoryDropdownFactory.createFromJsonArray(campaignStatusData)
      const ProductFamilyOptions = CategoryDropdownFactory.createFromJsonArray(productFamily)
      const CountryDataOptions = CategoryDropdownFactory.createFromJsonArray(countryData)
      const AmsOptions = CategoryDropdownFactory.createFromJsonArray(ams)
      const OptimizersOptions = CategoryDropdownFactory.createFromJsonArray(optimizers)

      return {
        campaignData: {
          kpi: KPIOptions,
          objective: ObjectiveOptions,
        },
        campaignStatusData: CampaignStatusOptions,
        productData: {
          productFamily: ProductFamilyOptions,
          product: products,
        },
        countryData: CountryDataOptions,
        staffData: {
          ams: AmsOptions,
          optimizers: OptimizersOptions,
        },
      }
    },
  },
}
