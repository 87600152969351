export default class OpportunityOverview {
  constructor(item = {}) {
    return {
      account_id: item.account_id || '',
      account_name: item.account_name || '',
      management_fee_type: item.pricing_model || '',
      country: item.country || '',
      account_number: item.account_number || '',
      management_fee: item.management_fee || '',
      payment_terms: item.payment_terms || '',
      duration: item.duration || '',
      industry: item.industry || '',
      opportunity_id: item.opportunity_id || '',
      opt_id: item.opt_id || '',
      opportunity_name: item.opportunity_name || '',
      objective_name: item.objective || '',
      client_requirements: item.client_requirements || '',
    }
  }
}
