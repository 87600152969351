export default {
  namespaced: true,
  state: {
    options: [
      { text: 'Head Office', value: 'Head Office' },
      { text: 'Branch', value: 'Branch' },
    ],
  },

  getters: {
    getOptions(state) {
      return state.options
    },
  },

  mutations: {},

  actions: {},
}
