// import store from '../store'
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import router from '../router'

export default class ErrorHandler {
  constructor(error) {
    this.error = error
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          this.error401(error.response)
          break
        }
        case 404: {
          this.error404(error.response)
          break
        }
        case 411: {
          this.error411(error.response)
          break
        }
        case 422: {
          this.error422(error.response)
          break
        }
        case 500: {
          this.error500(error.response)
          break
        }
        default: {
          this.error500(error.response)
          break
        }
      }
    } else {
      this.networkError()
    }
    return this.error.response.data
  }

  error401 = response => {
    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: response.data.message,
      allowOutsideClick: false,
      timer: 1500,
    }).then(result => {
      if (result.isConfirmed) {
        router.push({ name: 'heroai-logout' })
      }
    })
  }

  error404 = response => {
    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: response.data.message,
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        router.push({
          name: 'page-not-found',
        })
      }
    })
  }

  error411 = response => {
    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: response.data.message,
      allowOutsideClick: false,
      timer: 1500,
    })
  }

  error422 = response => {
    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: response.data.message,
    })
  }

  error500 = response => {
    Vue.swal({
      icon: 'error',
      title: 'Oops...',
      text: response.data.message,
    })
  }

  networkError = () => {
    Vue.swal('Error',
      'There are some error happened. Please submit how to replicate the problem to engineering team.',
      'question')
  }
}
