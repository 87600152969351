import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class SystemDetail {
  constructor(item = {}) {
    return {
      creator: item.creator || '',
      last_modified_date: item.last_modified_date ? moment(item.last_modified_date).format(DATE_FORMAT) : '',
    }
  }
}
