export default [
  {
    path: '/channel-performance',
    name: 'channel-performance',
    component: () => import('@/views/channel-performance/list.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Channel Performance',
      breadcrumb: [
        {
          text: 'Channel Performance',
          active: true,
          to: '/channel-performance',
        },
      ],
      resource: 'Channel Performance',
      action: 'Access',
    },
  },
]
