import moment from 'moment-timezone'

export default {
  formatDefaultText(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return data.toString()
  },

  formatClientRequirements(data, invalid = null) {
    if (!data) {
      return invalid
    }

    if (data.length === 0) {
      return invalid
    }

    let list = '<ul>'

    data.forEach(item => {
      list += `<li>${item}</li>`
    })

    list += '</ul>'

    return list
  },

  formatFullName(data, invalid = null) {
    if (!data) {
      return invalid
    }

    if (data.last_name) {
      return `${data.first_name} ${data.last_name}`
    }

    return `${data.first_name}`
  },

  formatMoney(data, invalid = null) {
    if ((data !== 0 && !data) || data === '-') {
      return invalid
    }

    return Number(data).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).toString()
  },

  formatPercentage(data, invalid = null) {
    if (!data && data !== 0) {
      return invalid
    }

    return `${data}%`
  },

  formatUsaDate(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz('Asia/Bangkok').format('DD MMM YYYY')
  },

  formatDate(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz('Asia/Bangkok').format('YYYY-MM-DD')
  },

  formatAccountName(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return `${data.name}`
  },

  formatUsaDateSf(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz('Asia/Bangkok').format('M/D/YYYY')
  },

  formatUsaDateSfTm(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz('Asia/Bangkok').format('M/D/YYYY h:mm a')
  },
  formatUsaDateTm(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz('Asia/Bangkok').format('DD MMMM YYYY h:mm a')
  },

  /**
   * Convert date format from 2022-07-01 to 01 Jul 2022.
   *
   * @param data
   * @param invalid
   * @param timezone
   * @returns {string|null}
   *
   */
  formatEnglishDateDefault(data, invalid = null, timezone = 'Asia/Bangkok') {
    if (!data) {
      return invalid
    }

    return moment(data).tz(timezone).format('DD MMM YYYY')
  },

  /**
   * Convert date format from ISO to 01 Jul 2022 00:00:00.
   *
   * @param data
   * @param invalid
   * @param timezone
   * @returns {string|null}
   *
   */
  formatEnglishDateTimeDefault(data, invalid = null, timezone = 'Asia/Bangkok') {
    if (!data) {
      return invalid
    }

    return moment(data).tz(timezone).format('DD MMM YYYY HH:mm:ss')
  },
}
