import Vue from 'vue'
import moment from 'vue-moment'
import {
  BootstrapVue,
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate'
import vuetify from '@/plugins/vuetify'
import 'leaflet/dist/leaflet.css'

// vue-google-oauth2
import GAuth from 'vue-google-oauth2'
import mixins from '@/plugins/mixins'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Composition API
Vue.use(VueCompositionAPI)
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
Vue.use(moment)
localize('en', en)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import 3rd party styles
require('@core/scss/vue/libs/vue-select.scss')
require('@core/scss/vue/libs/vue-sweetalert.scss')
require('@core/scss/vue/libs/vue-flatpicker.scss')
require('@/@core/scss/vue/libs/vue-good-table.scss')
require('animate.css') // Sweet Alert Animation

// vue-google-oauth2
const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'consent',
}

Vue.config.productionTip = false

// vue-google-oauth2
Vue.use(GAuth, gauthOption)
Vue.mixin(mixins)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
