import CategoryDropdown from '@/models/Campaigns/CategoryDropdown'

export default class CategoryDropdownFactory {
  static createFromJson(json) {
    return new CategoryDropdown(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    if (jsonArray) {
      jsonArray.forEach(item => {
        jsonData.push(CategoryDropdownFactory.createFromJson(item))
      })
    }
    return jsonData
  }
}
