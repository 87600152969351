export default [
  // เมนูหมวด PowerBI

  {
    path: '/powerbi/iconic-campaign-monitor',
    name: 'powerbi-iconic-campaign-monitor',
    component: () => import('@/views/reports/powerbi/IconicCampaignMonitor.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'ICONIC Campaign Monitor',
          active: true,
          to: '/powerbi/iconic-campaign-monitor',
        },
      ],
      resource: 'ICONIC Campaign Monitor',
      action: 'Access',
    },
  },
  {
    path: '/powerbi/sales-performance-th',
    name: 'powerbi-sales-performance-th',
    component: () => import('@/views/reports/powerbi/SalesPerformanceTh.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Sales Performance (TH)',
          active: true,
          to: '/powerbi/sales-performance-th',
        },
      ],
      resource: 'Sales Performance (TH)',
      action: 'Access',
    },
  },
  // {
  //   path: '/powerbi/payment-calendar',
  //   name: 'powerbi-payment-calendar',
  //   component: () => import('@/views/reports/powerbi/PaymentCalendar.vue'),
  //   meta: {
  //     requireLogin: true,
  //     pageTitle: 'Reports',
  //     breadcrumb: [
  //       {
  //         text: 'Payment Calendar',
  //         active: true,
  //         to: '/powerbi/payment-calendar',
  //       },
  //     ],
  //     resource: 'Payment Calendar',
  //     action: 'Access',
  //   },
  // },

  {
    path: '/powerbi/benchmark-by-industry',
    name: 'powerbi-benchmark-by-industry',
    component: () => import('@/views/reports/powerbi/BenchmarkByIndustry.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Benchmark By Industry',
          active: true,
          to: '/powerbi/benchmark-by-industry',
        },
      ],
      resource: 'Benchmark By Industry',
      action: 'Access',
    },
  },

  // เมนูหมวด Google Sheet

  {
    path: '/google-sheet/pmkt-bi-weekly-mom',
    name: 'google-sheet-pmkt-bi-weekly-mom',
    component: () => import('@/views/reports/google-sheet/PmktBiWeeklyMom.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'PMKT Bi-Weekly MOM',
          active: true,
          to: '/google-sheet/pmkt-bi-weekly-mom',
        },
      ],
      resource: 'PMKT Bi-Weekly MOM',
      action: 'Access',
    },
  },

  {
    path: '/google-sheet/missing-aci',
    name: 'google-sheet-missing-aci',
    component: () => import('@/views/reports/google-sheet/MissingAci.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Missing ACI',
          active: true,
          to: '/google-sheet/missing-aci',
        },
      ],
      resource: 'Missing ACI',
      action: 'Access',
    },
  },
]
