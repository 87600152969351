// eslint-disable-next-line import/no-cycle
import store from '@/store'

const isAuthenticated = store.getters['heroAiAuthentications/isAuthenticated']

let meta = {
  requireLogin: true,
  pageTitle: 'Google Ads Mockup',
  breadcrumb: [
    {
      text: 'Google Ads Mockup Group',
      active: false,
      to: '/google-ads-mockup-group',
    },
    {
      text: 'Google Ads Mockup',
      active: true,
      to: '/google-ads-mockup-group/:groupId',
    },
  ],
  // resource: 'Google Ads Mockup',
  // action: 'Access',
}

if (!isAuthenticated) {
  meta = {
    layout: 'full',
  }
}

export default [
  {
    path: '/google-ads-mockup-group',
    name: 'google-ads-mockup-group-list',
    component: () => import('@/views/google-ads-mockup/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Google Ads Mockup Group',
      breadcrumb: [
        {
          text: 'Google Ads Mockup Group',
          active: true,
          to: '/google-ads-mockup-group',
        },
      ],
      // resource: 'Google Ads Mockup',
      // action: 'Access',
    },
  },
  {
    path: '/google-ads-mockup-group/:groupId',
    name: 'google-ads-mockup-list',
    component: () => import('@/views/google-ads-mockup/GoogleAdsMockupList.vue'),
    meta,
  },
  {
    path: '/google-ads-mockup-generator/:id?',
    name: 'google-ads-mockup-generator',
    component: () => import('@/views/google-ads-mockup/GoogleAdsMockupGenerator.vue'),
    meta: {
      layout: 'full',
    },
  },
]
