export default {
  namespaced: true,
  state: {
    opportunityId: '',
    opportunityAmount: 0,
    opportunityRemaining: 0,
  },

  getters: {
    getOpportunityId(state) {
      return state.opportunityId
    },
    getOpportunityAmount(state) {
      return state.opportunityAmount
    },
    getOpportunityRemaining(state) {
      return state.opportunityRemaining
    },
  },

  mutations: {
    clearData(state) {
      state.opportunityId = ''
      state.opportunityAmount = 0
      state.opportunityRemaining = 0
    },
    setOpportunityId(state, payload) {
      state.opportunityId = payload
    },
    setOpportunityAmount(state, payload) {
      state.opportunityAmount = payload
    },
    setOpportunityRemaining(state, payload) {
      state.opportunityRemaining = payload
    },
  },
}
