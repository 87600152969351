export default [
  {
    path: '/herovision/:campaignId?/:tabName?',
    name: 'herovision-index',
    component: () => import('@/views/herovision/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'HeroVisionX',
      breadcrumb: [
        {
          text: 'HeroVisionX',
          active: true,
          to: '/herovision',
        },
      ],
      resource: 'HeroVisionX',
      action: 'Access',
    },
  },
]
