const defaultState = {
  currentPage: 1,
  perPage: 25,
  searchText: '',
  filters: {
    submitted: true,
    answered: true,
    missedCall: true,
    chatbot: true,
  },
  status: [],
  heroVisionFilterId: '',
}

export default {
  namespaced: true,
  state: { ...defaultState },

  getters: {
    getCurrentPage(state) {
      return state.currentPage
    },
    getPerPage(state) {
      return state.perPage
    },
    getSearchText(state) {
      return state.searchText
    },
    getFilters(state) {
      return state.filters
    },
    getStatus(state) {
      return state.status
    },
    getHeroVisionFilter(state) {
      return state.heroVisionFilterId
    },
  },

  mutations: {
    setCurrentPage(state, payload) {
      state.currentPage = payload
    },
    setPerPage(state, payload) {
      state.perPage = payload
    },
    setSearchText(state, payload) {
      state.searchText = payload
    },
    setFilters(state, payload) {
      state.filters = payload
    },
    setStatus(state, payload) {
      state.status = payload
    },
    setHeroVisionFilter(state, payload) {
      state.heroVisionFilterId = payload
    },
    setDefaultState(state) {
      Object.assign(state, defaultState)
    },
  },
}
